import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { App, Select, Tooltip } from 'antd';
import { useAppSelector } from 'hooks/hooks';
import style from 'assets/styles/selectEngine.module.scss';
import { CloudIcon, MonitorIcon } from 'assets/icons/svg';
import { Cond } from 'utils/Cond';
import {
	keepPreviousData,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query';
import { ipc, isDesktop } from 'desktop';
import enginesService from 'services/EnginesService';
import { errorNotification } from 'utils/Notifications';
import { authState } from 'store/slices/auth';
import { useLocalSettings } from 'settings';
import {
	getLocalApplication,
	LocalApplication,
} from 'desktop/LocalApplications';
import { EntityStatus } from 'api';

interface Props {
	setSelectedEngine: Dispatch<SetStateAction<EngineOption | undefined>>;
	selectedEngine?: EngineOption | undefined;
}

export interface EngineOption {
	version: string;
	installed:
		| {
				appCodes: string[];
				installPath: string;
				localApplications: LocalApplication[];
				name: string;
		  }
		| undefined;
	online:
		| { engineId: number; name: string; entityStatus: EntityStatus }
		| undefined;
	latestTag: boolean;
	upgradeTag: boolean;
}

export const SelectEngineForHome: React.FC<Props> = (props) => {
	const { isOnline, isGuest } = useAppSelector(authState);
	const { setSelectedEngine, selectedEngine } = props;
	const [rememberedEngine, setRememberedEngine] =
		useLocalSettings('engineSelected');
	const queryClient = useQueryClient();

	const { notification } = App.useApp();

	const onChange = (value: string) => {
		const engineFound = engines.find((engine) => engine.version === value);
		if (engineFound === undefined) return;
		setSelectedEngine(engineFound);
		if (isDesktop) {
			setRememberedEngine(engineFound.version);
		}
	};

	const {
		data: installedEngineOptions,
		error: fetchInstalledEnginesError,
		isLoading: loadingInstalledEngines,
	} = useQuery({
		queryKey: ['engines', 'home', 'installed'],
		queryFn: () =>
			ipc.getInstalledEngines().then((res) =>
				res.map(
					(installedEngine) =>
						({
							version: installedEngine.version,
							installed: {
								appCodes: installedEngine.apps,
								installPath: installedEngine.install_path,
								localApplications: installedEngine.apps.map((code) =>
									getLocalApplication(code)
								),
								name: installedEngine.name,
							},
							online: undefined,
							latestTag: false,
							upgradeTag: false,
						} as EngineOption)
				)
			),
		initialData: [],
		enabled: isDesktop,
		placeholderData: keepPreviousData,
	});

	const {
		data: onlineEngineOptions,
		error: fetchPublishedEnginesError,
		isLoading: loadingPublishedEngines,
	} = useQuery({
		queryKey: ['engines', 'home', 'online'],
		queryFn: () =>
			enginesService.getPublishedEngines().then((res) =>
				res.data.map(
					(onlineEngine) =>
						({
							version: onlineEngine.version,
							installed: undefined,
							online: {
								engineId: onlineEngine.id,
								name: onlineEngine.name,
								entityStatus: onlineEngine.entityStatus,
							},
							latestTag: false,
							upgradeTag: false,
						} as EngineOption)
				)
			),
		initialData: [],
		enabled: isOnline && !isGuest,
		placeholderData: keepPreviousData,
	});

	const {
		data: engines,
		error: combineEnginesError,
		isLoading: isLoadingCombinedEngines,
		refetch: combineEngines,
	} = useQuery({
		queryKey: ['engines', 'home', 'combined'],
		initialData: [],
		enabled: !loadingPublishedEngines && !loadingInstalledEngines,
		staleTime: 1000 * 60 * 60,
		gcTime: 1000 * 60 * 60,
		queryFn: () => {
			const sortedList: EngineOption[] = [
				...onlineEngineOptions,
				...installedEngineOptions,
			].sort((a, b) => {
				const a_version = !!a.version ? a.version : '0';
				const b_version = !!b.version ? b.version : '0';

				return b_version.localeCompare(a_version, 'en', { numeric: true });
			});
			try {
				if (sortedList.length === 0) {
					return sortedList;
				}

				let firstOption: EngineOption = sortedList.at(0)!;
				if (!isDesktop) {
					firstOption.latestTag = true;
					return sortedList;
				}

				// Condense engines with same version into one with both online and installed properties set
				let condensedList: EngineOption[] = [];
				let tmpGroupedMajor: EngineOption[] = [firstOption]; // Fix exclamation!s Make this a single instead of a list! Reduced in the bottom of for loop!
				let prevGeneration: string = firstOption.version.split('.').at(0)!;
				for (let i = 1; i < sortedList.length; i++) {
					let previousOption = tmpGroupedMajor.at(tmpGroupedMajor.length - 1)!;
					let currentOption = sortedList.at(i)!;

					const currentGeneration: string = currentOption.version
						.split('.')
						.at(0)!;

					// If engine does not have a version (legacy engines => skip)
					if (
						currentOption.version === undefined ||
						currentOption.version === ''
					) {
						continue;
					}

					// If the algorithm is at the beginning of engine options with same major
					if (tmpGroupedMajor.length === 0) {
						tmpGroupedMajor.push(currentOption);
						continue;
					}

					let engineOptionCurrentMajorHighestVersion: EngineOption =
						tmpGroupedMajor.at(0)!;

					// Check if major version change and tag the latest in the prev section!
					if (currentGeneration !== prevGeneration) {
						if (
							!!engineOptionCurrentMajorHighestVersion.online &&
							!engineOptionCurrentMajorHighestVersion!.installed
						) {
							for (let j = 1; j < tmpGroupedMajor.length; j++) {
								if (
									!!tmpGroupedMajor.at(j)!.installed &&
									engineOptionCurrentMajorHighestVersion.version !==
										tmpGroupedMajor.at(j)!.version
								) {
									engineOptionCurrentMajorHighestVersion.upgradeTag = true;
									break;
								}
							}
						}
						condensedList.push(...tmpGroupedMajor);
						tmpGroupedMajor = []; // Reset list for the next generation options
						prevGeneration = currentGeneration;
						tmpGroupedMajor.push(currentOption);
						continue;
					}

					if (previousOption.version !== currentOption.version) {
						tmpGroupedMajor.push(currentOption);
						continue;
					}

					tmpGroupedMajor.pop();

					// Combine them
					let combinedOption: EngineOption = {
						installed: previousOption.installed ?? currentOption.installed,
						online: previousOption.online ?? currentOption.online,
						version: currentOption.version,
						latestTag: false,
						upgradeTag: false,
					} as EngineOption;

					tmpGroupedMajor.push(combinedOption);
				}

				if (
					tmpGroupedMajor.length > 0 &&
					!!tmpGroupedMajor.at(0)!.online &&
					!tmpGroupedMajor.at(0)!.installed
				) {
					for (let j = 1; j < tmpGroupedMajor.length; j++) {
						if (
							!!tmpGroupedMajor.at(j)!.installed &&
							tmpGroupedMajor.at(0)!.version !== tmpGroupedMajor.at(j)!.version
						) {
							tmpGroupedMajor.at(0)!.upgradeTag = true;
							break;
						}
					}
				}
				condensedList.push(...tmpGroupedMajor);

				let latestRelease = condensedList.find((option) => !!option.online);
				if (!!latestRelease) {
					latestRelease.latestTag = true;
				}

				return condensedList;
			} catch (e: any) {
				console.error('Failed tag/reduce engine list!');
				return sortedList;
			}
		},
	});

	useEffect(() => {
		combineEngines();
	}, [onlineEngineOptions, installedEngineOptions, combineEngines]);

	useEffect(() => {
		if (engines.length > 0 && isDesktop) {
			const defaultEngine = engines[0];
			const preSelected: EngineOption | undefined = engines.find(
				(engine) => engine.version === rememberedEngine
			);

			if (preSelected !== undefined) setSelectedEngine(preSelected);
			else {
				setRememberedEngine(defaultEngine.version);
				setSelectedEngine(defaultEngine);
			}
		}
	}, [engines, rememberedEngine, setRememberedEngine, setSelectedEngine]);

	useEffect(() => {
		if (engines.length > 0 && !isDesktop) {
			const defaultEngine = engines[0];
			setSelectedEngine(defaultEngine);
		}
	}, [engines, setSelectedEngine]);

	const loading = isLoadingCombinedEngines;

	useEffect(() => {
		if (fetchInstalledEnginesError && installedEngineOptions.length > 0) {
			notification.error(
				errorNotification('Failed to fetch installed engines!')
			);
		}
	}, [fetchInstalledEnginesError, notification, installedEngineOptions]);

	useEffect(() => {
		if (fetchPublishedEnginesError && onlineEngineOptions.length > 0) {
			notification.error(
				errorNotification('Failed to fetch published engines online!')
			);
		}
	}, [fetchPublishedEnginesError, notification, onlineEngineOptions]);

	useEffect(() => {
		if (combineEnginesError) {
			notification.error(
				errorNotification('Failed to combine and tag engines!')
			);
		}
	}, [combineEnginesError, notification]);

	return (
		<div className={style.selectEngineWrapper}>
			<Select
				loading={loading}
				className={style.selectEngineField}
				placeholder="Select an engine"
				value={selectedEngine?.version}
				onChange={onChange}
				getPopupContainer={(trigger) => trigger.parentNode}
				options={engines.map((engine) => ({
					label: (
						<div
							style={{ display: 'flex', width: '100%' }}
							key={
								engine.version +
								(!!engine.online ? engine.online.name : engine.installed?.name)
							}
							onMouseEnter={() => {
								if (!!engine.online && !engine.installed) {
									const engineId = engine.online.engineId;
									queryClient.prefetchQuery({
										queryKey: ['engines', engineId, 'applications'],
										queryFn: () =>
											enginesService
												.getApplicationsByEngineId(engineId)
												.then((res) => res.data),
										initialData: [],
									});
									queryClient.prefetchQuery({
										queryKey: ['engines', engineId, 'applications', 'releases'],
										queryFn: () =>
											enginesService
												.getApplicationReleasesByEngineId(engineId)
												.then((res) => res.data),
										initialData: [],
									});
								}
							}}
						>
							<div className={style.labelIconName} style={{ width: '60%' }}>
								<Cond if={!!engine.online}>
									<Tooltip
										title={
											engine.online?.entityStatus === 'PUBLISHED_INTERNALLY'
												? 'Published Internally'
												: undefined
										}
									>
										<div
											style={
												engine.online?.entityStatus === 'PUBLISHED_INTERNALLY'
													? {
															display: 'inline-flex',
															color: 'var(--add-yellow)',
													  }
													: { display: 'inline-flex' }
											}
										>
											<CloudIcon size={22} zoomResize />
										</div>
									</Tooltip>
								</Cond>
								<Cond if={!!engine.installed}>
									<MonitorIcon zoomResize />
								</Cond>
								<div>
									{engine.online ? engine.online.name : engine.installed?.name}
								</div>
								<Cond if={engine.latestTag}>
									<div
										className={`${style.tag} ${style.latestReleaseTagColor}`}
									>
										Latest Release
									</div>
								</Cond>
								<Cond if={engine.upgradeTag}>
									<div className={`${style.tag} ${style.upgradeTagColor}`}>
										Upgrade
									</div>
								</Cond>
							</div>
							<div style={{ width: '40%' }}>{engine.version}</div>
						</div>
					),
					value: engine.version,
				}))}
			/>
		</div>
	);
};
