import { useState, useEffect, useRef, FC } from 'react';
import { useAppSelector } from 'hooks/hooks';
import { NewsCard } from 'components/cards';
import { Section } from 'components/Section';
import { App, Spin, Button, Tour } from 'antd';
import { isDesktop, useDesktopContext } from 'desktop';
import { authState } from 'store/slices/auth';
import style from 'assets/styles/homePage.module.scss';
import { Cond } from 'utils/Cond';
import { AppsToTrySection } from './AppsToTrySection';
import { RecentFilesSection } from './RecentFilesSection';
import { IncludedAppsSection } from './IncludedAppsSection';
import { EnginesSection } from './EnginesSection';
import { Link } from 'react-router-dom';
import { EngineOption } from 'components/selects';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import newsService from 'services/NewsService';
import { mcErrorNotification } from 'utils/Notifications';
import { TourComponent } from 'components/StepsTour';
import { QuestionMarkIcon } from 'assets/icons/svg';
import { tourState } from 'store/slices/tour';
import { useLocalSettings } from 'settings';

export const HomePage: FC = () => {
	const { isGuest } = useAppSelector(authState);
	const queryClient = useQueryClient();
	const { notification } = App.useApp();
	const { open: isDialogOpen } = useAppSelector(tourState);
	const [tourDismissed] = useLocalSettings('dismissIntroductionTour');

	const {
		data: news,
		isLoading: isLoadingNews,
		error: getNewsError,
	} = useQuery({
		queryKey: ['news', 'home'],
		queryFn: () =>
			newsService
				.getNewsList(0, 3, undefined, 'PUBLISHED', undefined, undefined)
				.then((res) => res.data.content),
		initialData: [],
		enabled: !isGuest,
	});

	useEffect(() => {
		if (!getNewsError) return;
		notification.warning(
			mcErrorNotification('Warning', getNewsError, 'fetch', 'news')
		);
	}, [getNewsError, notification]);

	const { recentFiles } = useDesktopContext();

	const [selectedEngine, setSelectedEngine] = useState<
		EngineOption | undefined
	>(undefined);

	const refWhatsNew = useRef<HTMLDivElement>(null);
	const refEngines = useRef<HTMLDivElement>(null);
	const refIncludedApps = useRef<HTMLDivElement>(null);
	const refAppsToTry = useRef<HTMLDivElement>(null);

	const [openTour, setOpenTour] = useState<boolean>(false);

	const steps = isGuest
		? [
				{
					title: 'Engines',
					description:
						'Here you can see and manage available Tema Platform engines.',
					target: () => refEngines.current || document.createElement('div'),
				},
				{
					title: 'Included Applications',
					description:
						'These are the applications included in the selected engine.',
					target: () =>
						refIncludedApps.current || document.createElement('div'),
				},
		  ]
		: [
				{
					title: "What's new?",
					description: 'Check out the latest updates here.',
					target: () => refWhatsNew.current || document.createElement('div'),
				},
				{
					title: 'Engines',
					description:
						'Here you can see and manage available Tema Platform engines.',
					target: () => refEngines.current || document.createElement('div'),
				},
				{
					title: 'Included Applications',
					description:
						'These are the applications included in the selected engine.',
					target: () =>
						refIncludedApps.current || document.createElement('div'),
				},
				{
					title: 'Apps to Try',
					description: 'Explore new applications you might be interested in.',
					target: () => refAppsToTry.current || document.createElement('div'),
				},
		  ];

	return (
		<div
			style={{
				position: 'relative',
			}}
		>
			<Button
				className="sm-screen-hidden"
				size="small"
				type="dashed"
				onClick={() => setOpenTour(true)}
				style={{
					position: 'absolute',
					right: '0',
					bottom: '0',
					zIndex: 99,
					aspectRatio: '1',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					padding: '1rem 0.5rem',
					borderRadius: '50%',
				}}
			>
				<QuestionMarkIcon />
			</Button>
			{isDialogOpen && !tourDismissed && <TourComponent />}
			<Spin spinning={isLoadingNews} size="large">
				<div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
					<Cond if={!isGuest}>
						<Section
							title="What's new?"
							row
							style={{ marginTop: '0', position: 'relative' }}
							ref={refWhatsNew}
						>
							<Link
								to="/news"
								style={{ position: 'absolute', right: 0, top: '0.25rem' }}
							>
								News Archive
							</Link>
							{news.map((el) => (
								<div
									className={style.cardWrapper}
									style={{ marginBottom: 0 }}
									key={el.id}
									onMouseEnter={() => {
										queryClient.prefetchQuery({
											queryKey: ['news', el.id],
											queryFn: () =>
												!!el.id
													? newsService
															.getNewsItemById(el.id)
															.then((res) => res.data)
													: undefined,
										});
									}}
								>
									<NewsCard news={el} />
								</div>
							))}
						</Section>
					</Cond>

					<div className="groupContainer">
						<div ref={refEngines}>
							<EnginesSection
								selectedEngine={selectedEngine}
								setSelectedEngine={setSelectedEngine}
							/>
						</div>

						<div ref={refIncludedApps}>
							<Spin spinning={isLoadingNews} size="large">
								<IncludedAppsSection selectedEngine={selectedEngine} />
							</Spin>
						</div>

						<Cond if={isDesktop}>
							<RecentFilesSection
								selectedEngine={selectedEngine}
								recentFiles={recentFiles}
							/>
						</Cond>
					</div>

					<Cond if={!isGuest}>
						<div ref={refAppsToTry}>
							<AppsToTrySection />
						</div>
					</Cond>
				</div>
			</Spin>
			<Tour open={openTour} onClose={() => setOpenTour(false)} steps={steps} />
		</div>
	);
};
